import React, { useEffect, useState, useContext } from "react"

import GamesLayout, { EventsContext } from '../../components/GamesLayout';
import useMatchedQuery from '../../hooks/useMatchedQuery';
import EventDisplay from '../../components/EventDisplay';
import EventData from "../../StructureData/EventData";
import { navigate } from "gatsby";

const EventWrapper = (props) => {
  const { sourceId } = props
  const [event, setEvent] = useState()
  const events = useContext(EventsContext)
  useEffect(() => {
    if (events && events.list && events.findById) {
      const found = events.findById(sourceId)
      if (found) {
        setEvent(found)
      } else {
        navigate('/app/evento/')
      }
    }
  }, [events, sourceId])
  return (
    <>
      {event && <>
        <EventData event={event} />
        <EventDisplay {...props} event={event} render={this.renderEvent} />
      </>
      }
    </>
    
  )
}

export default function Event(props) {
  const {sourceId} = props
  const matchedQuery = useMatchedQuery()
  const { ...context } = props.pageContext

  return (
    <GamesLayout {...context} matchedQuery={matchedQuery} useHeader={true} useContainer={true} withSearch={false} title={"Evento"}>
      <EventWrapper sourceId={sourceId} matchedQuery={matchedQuery} />
    </GamesLayout>
  )
}
